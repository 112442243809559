@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

/* @font-face {
font-family: "Gotham-Medium";
src: local("Gotham-Medium"),
 url("./fonts/Gotham-Medium.otf") format("truetype");
font-weight: bold;
}

@font-face {
font-family: "Gotham-Bold";
src: local("Gotham-Bold"),
 url("./fonts/Gotham-Bold.otf") format("truetype");
font-weight: bold;
} */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.coupon-container {
  display: grid;
  grid-template-columns: 70% 28%;
  padding: 0 1rem;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 2.5rem;
  }
}